// Here you can add other styles

// Sidebar
.selectIconMenu{
    max-height: 200px;
    overflow-y: auto;
}
.pr-3{
    padding-right: 15px;
}
.mr-3{
    margin-right: 15px;
}
.ml-3{
    margin-left: 15px;
}s
.ml-2{
    margin-left: 10px;
}
body, html {
    background: #f8f9fa;
}
.sidebar{
    // background: #ffffff;
    background: #05668E;


    /* Works on Firefox */
    .sidebar-nav {
        scrollbar-width: thin;
        scrollbar-color: #ffffff #2f93bd;
    }
    
    /* Works on Chrome, Edge, and Safari */
    .sidebar-nav::-webkit-scrollbar {
        width: 12px;
    }
    
    .sidebar-nav::-webkit-scrollbar-track {
        background: #2f93bd;
    }
    
    .sidebar-nav::-webkit-scrollbar-thumb {
        background-color: #ffffff;
        border-radius: 0px;
        border: 3px solid #2f93bd;
    }
}

.card{
    box-shadow: 0 0 13px 0 rgb(71 58 86 / 5%);
}

.wrapper{
    background: #f8f9fa !important;
    .header{
        &.header-sticy{
            box-shadow: 0 0 13px 0 rgb(71 58 86 / 5%);
            border: 0 
        }  
    }
}

fieldset{
    border: 1px solid var(--cui-border-color, #d8dbe0) !important;
    padding-left: 0.75em !important;
    padding-right: 0.75em !important;
    padding-bottom: 0.35em;
    margin-bottom: 0.75em;
    background-color: #fdfdfd;
    
    legend{
        float: none !important;
        width: auto !important;
        font-size: inherit;
        padding: inherit;
        font-weight: 500;
    }
}

.btn-success{
    color: #ffffff;
    
    &:hover{
        color: #ffffff;
    }
}

.justify-content-center{
    justify-content: center;
}
.nav-pills{
    background: #fdfdfd;
    .nav-item {
        width: 100%;
        &.active{
            background-color: var(--cui-nav-pills-link-active-bg, #05668e);
            width: 100%;
        }
    }
}